<!--=============================
=            APPCARD            =
==============================-->

<template>
  <v-card elevation="2"  outlined class="rounded-lg outline ma-3 pa-2">
    <v-card-title>
     <slot name="card-header"></slot>
    </v-card-title>

    <v-card-text class="pa-4">
      <slot name="card-body"></slot>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "base-card",
  data() {
    return {
     
     
    }
  }, computed: {
      height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    },
};
</script>
<style scoped>

</style>

<!--====  End of APPCARD  ====-->