<template>
  <v-container>
    <base-header>
      <div slot="page-header">
        <v-row align="center">
          <h2 class="font-weight-black primary--text">Raelics</h2>
          <v-btn
              fab
              x-small
              class="ml-4" rounded color="#0216f2" @click="refreshUserTokens()"
              v-if="filterByUserId">
            <v-icon color="white" v-if="!loading">mdi-refresh</v-icon>
            <v-progress-circular
                width="2"
                size="14"
                v-if="loading"
                indeterminate
                color="white"
            ></v-progress-circular>
          </v-btn>
        </v-row>
      </div>
      <div slot="page-search">
        <v-card elevation="0">
          <v-card-title v-if="!filterByUserId">
            <v-spacer></v-spacer>
            <v-form @submit.prevent="doSearch">
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-form>
          </v-card-title>
        </v-card>
      </div>
    </base-header>
    <v-data-table v-model="selected"
                  @click:row="goToTokenDetail"
                  height="60vh"
                  :headers="headers"
                  :loading="loading"
                  :items="tokens"
                  :page="pagination.page"
                  :footer-props="{
                          'items-per-page-options':[5, 10, 30, 80, 200],
                          itemsPerPageText:''
                        }"
                  @update:items-per-page="itemsPerPageChange"
                  @update:page="pageChange"
                  :server-items-length="pagination.totalItems"
                  :items-per-page="pagination.rowsPerPage"
                  loading-text="Loading... Please wait"
                  item-key="name"
                  :class="[selected && 'selected']"
                  class="elevation-1">
      <template v-slot:item.title="{ item }">
        <div class="py-6 px-3">
          <v-row align="center">
            <v-img
                :src="item.previewUrl ? item.previewUrl.replaceAll('ipfs://', 'https://cloudflare-ipfs.com/ipfs/'):''"
                max-width="50" max-height="50" width="100%" height="100%"
                aspect-ratio="0.5"/>
            <span class="ml-4">
              <v-icon v-if="item.externalStatus==='INTERNAL'" size="12">mdi-star</v-icon>
              <v-icon v-if="item.externalStatus==='EXTERNAL'" size="12">mdi-cloud-download</v-icon>
              {{ item.title }}
            </span>
            <a
                @click="$event.stopPropagation()"
                style="text-decoration: none;"
                :href="`/token/${item.id}?is_external=${item.externalStatus === 'EXTERNAL'}`">
              <v-btn
                  class="ml-2"
                  style="width:20px;height:20px;"
                  x-small
                  fab
                  elevation="1"
              >
                <v-icon size="14">mdi-link</v-icon>
              </v-btn>
            </a>
          </v-row>
        </div>
      </template>
      <template v-slot:item.previewUrl="{ item }">
        <v-icon color="warning" v-if="item.type === 'IMAGE' && item.previewUrl && item.previewUrl.indexOf('cloudinary.com/') === -1">mdi-alert</v-icon>
        <v-icon color="green" v-if="item.type === 'IMAGE' && item.previewUrl && item.previewUrl.indexOf('cloudinary.com/') !== -1">mdi-check</v-icon>
        <v-icon v-if="item.type === 'VIDEO'">mdi-video</v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="showNftHideConfirm" width="300">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to block {{ nftToHide && nftToHide.title }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showNftHideConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain @click="setNftTokenStatus(nftToHide.id, 'BLOCKED')">
          Block
        </v-btn>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="showNftUnlockConfirm" width="300">
      <v-sheet class="px-7 pt-7 pb-4 mx-auto text-center d-inline-block" :dark="$vuetify.theme.dark">
        <div class="text-body-2 mb-4">
          Are you sure to unblock {{ nftToUnlock && nftToUnlock.title }}?<br/>
        </div>

        <v-btn :disabled="loading" class="ma-1" color="grey" plain @click="showNftUnlockConfirm=false;">
          Cancel
        </v-btn>

        <v-btn :loading="loading" class="ma-1" color="error" plain
               @click="setNftTokenStatus(nftToUnlock.id, 'AVAILABLE')">
          Unblock
        </v-btn>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseCard from "../../components/Base/BaseCard.vue";
import BaseHeader from "../../components/Base/BaseHeader.vue";
import axios from "axios";

export default {
  name: 'tokens',
  components: {
    BaseCard,
    BaseHeader
  },
  methods: {
    goToTokenDetail(token) {
      console.log('token', token.externalStatus)
      this.$router.push({
        path: `/token/${token.id}`,
        query: {
          is_external: token.externalStatus === 'EXTERNAL'
        }
      })
    },
    pageChange(newPage) {
      this.pagination.page = newPage
      this.fetchTokens()
    },
    itemsPerPageChange(newPerPage) {
      this.pagination.rowsPerPage = newPerPage
      this.fetchTokens()
    },
    setHideNFt(nftItem) {
      this.nftToHide = nftItem
      this.showNftHideConfirm = true
    },
    setUnlockNFt(nftItem) {
      this.nftToUnlock = nftItem
      this.showNftUnlockConfirm = true
    },
    setNftTokenStatus(tokenId, status) {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + `/admin/nft/${tokenId}/status`, {status}).then(resp => {
        this.fetchTokens()
        this.showNftHideConfirm = false;
        this.showNftUnlockConfirm = false;
      }).catch(err => {
        return Promise.reject(null)
      }).finally(() => {
        this.loading = false;
      })
    },
    doSearch() {
        this.fetchTokens()
    },
    refreshUserTokens() {
      this.loading = true;
      axios.post(process.env.VUE_APP_BASE_API_URL + '/admin/user/refresh-owned-tokens?userId=' + this.filterByUserId).then(resp => {
      }).finally(() => {
        this.fetchTokens()
      })
    },
    fetchTokens() {
      this.loading = true;
      let url = process.env.VUE_APP_BASE_API_URL + '/admin/nft';
      console.log('filterByUserId', this.filterByUserId)
      if (this.filterByUserId) {
        url = process.env.VUE_APP_BASE_API_URL + `/admin/user/${this.filterByUserId}/token`
      }
      axios.get(url, {
        params: {
          page: this.pagination.page - 1,
          size: this.pagination.rowsPerPage,
          sort: 'created,desc',
          search: this.search,
        }
      }).then(resp => {
        this.tokens = resp.data.content
        this.pagination.totalItems = resp.data.totalElements

      }).finally(() => {
        this.loading = false;
      })
    }
  },
  created() {
    this.$watch(
        () => this.filterByUserId,
        (toValue, previousValue) => {
          if (toValue) {
            this.fetchTokens()
          }
        }
    )
    this.fetchTokens()
  },
  props: {
    filterByUserId: {
      type: String | null,
      default: () => null,
    },
  },
  data() {
    let data = {
      pagination: {
        descending: true,
        sortBy: 'created',
        rowsPerPage: 10,
        page: 1,
        totalItems: 0,
      },
      search: null,
      showNftHideConfirm: false,
      showNftUnlockConfirm: false,
      nftToHide: null,
      nftToUnlock: null,
      loading: false,
      tokens: [],
      selected: [],
      headers: [{
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'title',
      },
        {
          text: 'Address',
          sortable: false,
          value: 'contractAddress'
        },
        {
          text: 'Owned by',
          sortable: false,
          value: 'owner.userInfo.nickname'
        },
        {
          text: 'Created by',
          sortable: false,
          value: 'creator.userInfo.nickname'
        },
        {
          text: 'Has IPFS Preview',
          sortable: false,
          value: 'previewUrl'
        },
      ],
    }
    if (this.filterByUserId) {
      data.headers = [{
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'title',
      },
        {
          text: 'Address',
          sortable: false,
          value: 'contractAddress'
        },
        {
          text: 'Created by',
          sortable: false,
          value: 'creator.userInfo.nickname'
        },
        {
          text: 'Has IPFS Preview',
          sortable: false,
          value: 'previewUrl'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status'
        },
      ]
    } else {
      data.headers = [{
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'title',
      },
        {
          text: 'Address',
          sortable: false,
          value: 'contractAddress'
        },
        {
          text: 'Owned by',
          sortable: false,
          value: 'owner.userInfo.nickname'
        },
        {
          text: 'Created by',
          sortable: false,
          value: 'creator.userInfo.nickname'
        },
        {
          text: 'Optimized preview',
          sortable: false,
          value: 'previewUrl'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'status'
        },
      ]
    }
    return data;
  },
};
</script>
<style scoped>
.selected {
  cursor: pointer;
}
</style>
